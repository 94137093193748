<template>
  <div>
    <ScrollerWrap
        id="ScrollerWrap_3"
        :constrain-height="constrainHeight"
        :next="computedData?.next_page_url"
        class="scroller-wrap -height-80 -bottom-padding-150"
        @update-list="handleLazyUpdate"
    >
      <div>
        <table class="table table-hover text-left">
          <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">&nbsp;</th>
            <th scope="col">Subject</th>
            <th scope="col">Sent/Received</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in computedData?.data || computedData"
              :key="index"
              :class="['table-row', unreadStyle(item.read)]"
          >
            <td scope="row">

            </td>
            <td scope="row">
              <b>from:</b>&nbsp;{{ item.from || 'no from address' }}<br>
              <b>to:</b>&nbsp;{{ (item.to).join(',') }}
            </td>
            <td scope="row">
              <EmailPreviewModal
                  ref="emailPreview"
                  @doaction="loadEmailIframe(item.html_body)"
              >
                <template #btn-text>
                  {{ item.subject || "no subject" }}
                </template>
                <template #content>
                  <iframe width="600" Height="800" ref="emailIframe" frameborder="0"></iframe>
                </template>
              </EmailPreviewModal>
            </td>
            <td scope="row">
              {{ item.created_at }}
            </td>
            <td>
              {{ item }}
              <span class="link-text" @click="navComms(item.id)">
                View in Comms
              </span>
              <a :href="commsURL + '/' + item.id" target="_blank">
                
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ScrollerWrap>
  </div>
</template>
<script>
import starOutline from "@fa/regular/star.svg";
import starSolid from "@fa/solid/star.svg";
import received from "@fa/solid/share.svg";
import paperclip from "@fa/solid/paperclip.svg";
import sent from "@fa/solid/reply.svg";
import unread from "@fa/regular/paper-plane.svg";
import et from "@fa/solid/exclamation-triangle.svg";
import {mapActions, mapGetters} from "vuex";
import EmailPreviewModal from "@/components/modal/EmailPreviewModal";

export default {
  name: "AssignedEmailList",
  props: {
    parent: {
      type: String,
      default: 'tp'
    },
    constrainHeight: {
      type: Boolean,
      default: false
    },
    emailData: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    starOutline,
    starSolid,
    received,
    paperclip,
    sent,
    unread,
    et,
    lazyData: null,
    emailIframeContent: null
  }),
  components: {
    EmailPreviewModal,
  },
  computed: {
    computedData() {
      return this.lazyData || this.emailData;
    }
  },
  methods: {
    ...mapActions(["markRead", "markFlag", "previewEmail"]),
    handleLazyUpdate(data) {
      if (typeof data === 'undefined') return;
      this.lazyData = this.emailData
      this.lazyData.next_page_url = data.next_page_url
      this.lazyData.data = this.lazyData.data.concat(data.data)
    },
    async loadEmailIframe(html) {
      await this.$nextTick(() => {
        this.$refs.emailIframe[0].srcdoc = html;
      });
    },
    unreadStyle(read) {
      if (typeof read == "undefined") {
        read = true;
      }
      return {
        unread: !read
      };
    },
    navComms(id) {
      window.open(process.env.VUE_APP_COMMS_URL + '/message/' + id, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.outboundIframe {
  .edit-modal {
    iframe {
      width: 600px;
      height: 600px;
    }
  }
}

.unread {
  span {
    font-weight: bold !important;
  }
}

.pointer-events-all {
  pointer-events: all;
}
</style>
